/**
 */
import {InjectionToken} from '@angular/core'
import {Client, Command} from '@smithy/smithy-client'
import {RequestPresigningArguments} from '@smithy/types'

export const WINDOW = new InjectionToken<Window>('window')
export const LOCAL_STORAGE = new InjectionToken<Storage>('localStorage')
export const SIGNED_URL = new InjectionToken<(
  client: Client<any, any, any, any>, command: Command<any, any, any>, options?: RequestPresigningArguments) => Promise<string>>('signedUrl')

export interface WebSocketFactory {
  create: () => WebSocket
}

export const WEB_SOCKET = new InjectionToken<WebSocketFactory>('webSocket')
