import {HttpClient} from '@angular/common/http'
import {inject, Injectable, signal, WritableSignal} from '@angular/core'
import {FamilyMenuDay, IAwsCredentials, IDetailedFamily, IInvitationMember, IUser, TShoppingList} from '@ellen/user-be'
import {
  BehaviorSubject,
  catchError,
  filter,
  first,
  forkJoin,
  map,
  NEVER,
  Observable,
  ReplaySubject,
  switchMap,
  tap
} from 'rxjs'
import {environment} from '../../environments/environment'
import {CREATING_USER_ID} from '../application/constants'
import {User} from '../utils/user.class'
import {DetailedFamily} from '../utils/detailed-family.class'
import {ICollectResult} from 'ellen-bankid-gw'

@Injectable({
  providedIn: 'root'
})

export class UserService {
  /**
   * Signal-flag to mark when a user is being updated. It can be used for nice
   * "placeholder-ing" and prettiness.
   */
  public isUpdatingUser$: WritableSignal<string | null> = signal(null)
  /**
   * Signal-flag to mark when family is being updated
   */
  public isUpdatingFamily$: WritableSignal<boolean> = signal(false)

  /**
   * Signal that represents logged-in user as family member.
   */
  public me$: WritableSignal<User> = signal(new User())

  /**
   * Observable that keeps track of currently logged family
   */
  public family$: Observable<DetailedFamily>

  public invitations$: Observable<IInvitationMember[]>
  private pInvitations$ = new ReplaySubject<IInvitationMember[]>(1)

  /**
   * Observable that keeps track of currently selected user.
   */
  public selectedUser$: Observable<User>

  public awsCredentials$: Observable<IAwsCredentials>

  public invite$ = new ReplaySubject<string>(1)

  private pAwsCredentials$ = new ReplaySubject<IAwsCredentials>(1)
  private pFamily$ = new ReplaySubject<DetailedFamily>(1)

  private pSelectedUser$: BehaviorSubject<User> =
    new BehaviorSubject<User>(new User())

  private http: HttpClient = inject(HttpClient)

  constructor() {
    // Initialise public observables
    this.selectedUser$ = this.pSelectedUser$.asObservable()
    this.family$ = this.pFamily$.asObservable()
    this.awsCredentials$ = this.pAwsCredentials$.asObservable()
    this.invitations$ = this.pInvitations$.asObservable()

    this.pAwsCredentials$.pipe(
      switchMap(() => this.invite$)
    ).subscribe(invite => {
      this.checkInvite(invite)
    })
  }

  /**
   * Method that should be called whenever the user logs out.
   * It's useful mainly when user is logged out because 401 or 403.
   */
  public reset() {
    this.pSelectedUser$.next(new User())
    this.me$.set(new User())
    this.isUpdatingUser$.set(null)
  }

  public getSelf(): Observable<DetailedFamily> {
    const url = `${environment.apiUrl}/user/self`

    return this.http.get<IDetailedFamily>(url).pipe(
      // Map interface to class
      map((f: IDetailedFamily) => new DetailedFamily(f)),
      tap((family: DetailedFamily) => {
        // Selected user should always be "me" when we recover "self"
        // But for now, it will be first member inside family
        family.invitations = family.invitations || []
        this.pAwsCredentials$.next(family.credentials)
        // If an update is made while editing users, then it will
        // be reset by this. But I simply do not feel like fixing
        // that too atm.
        this.pSelectedUser$.next(family.familyMembers[0])
        this.pFamily$.next(family)
        this.pInvitations$.next(family.invitations)
      })
    )
  }

  public closeAccount(): Observable<void> {
    const url = `${environment.apiUrl}/user/self`
    return this.http.delete<void>(url)
  }

  public saveUser(user: User, owner: string): Observable<DetailedFamily> {
    this.isUpdatingUser$.set(user.id)

    let url = `${environment.apiUrl}/user/${user.id}`
    if (user.id !== owner) {
      url = `${environment.apiUrl}/family/member/${user.id}`
    }
    return this.http.put<IUser>(url, user)
      .pipe(
        switchMap(() => this.family$),
        first(),
        tap(f => {
          f.familyMembers
            .filter(u => u.id === user.id)
            .forEach(u => Object.assign(u, user))
          // Stop loader
          this.pFamily$.next(f)
          this.isUpdatingUser$.set(null)
        })
      )
  }

  public addUser(user: User): Observable<DetailedFamily> {
    // Add a random user's ID now to make signal work.
    // It will be replaced once it is created for real.
    user.id = CREATING_USER_ID
    this.isUpdatingUser$.set(user.id)

    // Add user to current family
    this.family$
      .pipe(filter(Boolean), first())
      .subscribe(familyToUpdate => {
        familyToUpdate.familyMembers.push(user)
        this.pFamily$.next(familyToUpdate)
      })

    // Select new user so UI moves to that user in My Family.
    // It will basically will show a loader but with new user's face selected
    this.pSelectedUser$.next(user)

    const url = `${environment.apiUrl}/family/member`
    return this.http.put<IDetailedFamily>(url, user)
      .pipe(
        map((f: IDetailedFamily) => new DetailedFamily(f)),
        tap((family: DetailedFamily) => {
          // Save new family
          this.pFamily$.next(family)
          // Select created user so setting up can continue smoothly.
          // This time it will have a real ID.
          this.pSelectedUser$.next(family.familyMembers[family.familyMembers.length - 1])
          this.isUpdatingUser$.set(null)
        })
      )
  }

  public removeFromFamily(user: User): Observable<DetailedFamily> {
    // Switch to "me" user when removing a member
    this.switchUser(this.me$().id)
    // Start loader in user to be removed
    this.isUpdatingUser$.set(user.id)

    const url: string = `${environment.apiUrl}/family/member/${user.id}`

    return this.http.delete<IDetailedFamily>(url)
      .pipe(
        // Map interface to class
        map((f: IDetailedFamily) => new DetailedFamily(f)),
        // Stop loading & set users
        tap((family) => {
          this.isUpdatingUser$.set(null)
          this.pFamily$.next(family)
        })
      )
  }

  public saveFamilyShoppingList(newShoppingList: TShoppingList): Observable<TShoppingList> {
    this.isUpdatingFamily$.set(true)
    const url = `${environment.apiUrl}/family/${this.me$().familyId}/shopping`
    return forkJoin([
      this.http.put<TShoppingList>(url, newShoppingList),
      this.pFamily$.pipe(first())])
      .pipe(
        map(([value, family]) => {
          // Update family locally and stop loading
          family.shoppingList = value
          this.pFamily$.next(family)
          this.isUpdatingFamily$.set(false)
          return value
        }),
        catchError(() => {
          this.isUpdatingFamily$.set(false)
          return NEVER
        })
      )
  }

  public switchUser(id: string): void {
    this.family$.pipe(
      first()
    ).subscribe(f => {
      f.familyMembers
        .filter(u => u.id === id)
        .forEach(u => this.pSelectedUser$.next(u))
    })
  }

  public updateFamilySchedule(newSchedule: FamilyMenuDay[]): void {
    this.family$
      .pipe(filter(Boolean), first())
      .subscribe((f) => {
        f.schedule = newSchedule
        this.pFamily$.next(f)
      })
  }

  public impersonate(personNummer: string): Observable<ICollectResult> {
    const url: string = `${environment.bankIdGatewayUrl}/impersonate`
    return this.http.put<ICollectResult>(url, {personNummer})
  }

  public invite(email: string): Observable<any> {
    const url = `${environment.apiUrl}/invites`
    return this.http.put<any>(url, {email})
  }

  public acceptOrRejectInvite(accept: boolean, id: string): Observable<any> {
    let url = `${environment.apiUrl}/invites/${id}/`
    if (accept) {
      url += 'accept'
    } else {
      url += 'reject'
    }
    return this.http.put<any>(url, {id}).pipe(
      switchMap(() => {
        return this.getSelf()
      })
    )
  }

  private checkInvite(invite: string): void {
    const url = `${environment.apiUrl}/invites/${invite}/email`
    this.http.put(url, {}).subscribe()
  }
}
