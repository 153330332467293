import {
  FamilyMenuDay,
  IAwsCredentials,
  IDetailedFamily,
  IFamilyRecipe,
  IInvitationMember,
  TDailyMeal,
  TDay,
  TShoppingList
} from '@ellen/user-be'
import {User} from './user.class'

/**
 * Class that implements {@link IDetailedFamily} interface.
 * Similar to entity, but it will be used for frontend cases.
 * Instead of having just IDs in family members, it will have user entities.
 * Do not save this model in DB!
 *
 * When used, make sure that you are using the class, not the interface. When
 * a class get serialised, like when using API requests, it looses its class
 * properties and becomes a plain javascript object.
 */
export class DetailedFamily implements IDetailedFamily {
  id: string
  familyMembers: User[]
  familyAdmins: string[] = []
  schedule: FamilyMenuDay[]
  shoppingList: TShoppingList = []
  credentials: IAwsCredentials
  invitations: IInvitationMember[] = []

  constructor(obj: IDetailedFamily) {
    this.id = obj.id
    this.familyMembers = obj.familyMembers.map(fm => new User(fm))
    this.familyAdmins = obj.familyAdmins
    this.schedule = obj.schedule?.map(s => new FamilyMenuDay(s)) as any
    this.shoppingList = obj.shoppingList ?? []
    this.credentials = obj.credentials
    this.invitations = obj.invitations
  }

  public get quickMenuDay(): FamilyMenuDay | undefined {
    return this.schedule?.filter(r => r.day === 'quick')[0]
  }

  public get menuDays(): FamilyMenuDay[] | undefined {
    return this.schedule?.filter(r => r.day !== 'quick')
  }

  public getScheduleRecipe(day: TDay, meal: TDailyMeal): IFamilyRecipe | null {
    const found = this.schedule?.find(r =>
      ((day === 'quick' && r.day === day) ||
        (day !== 'quick' && Number(r.day) === Number(day)))
    )
    return found ? found[meal] ?? null : null
  }
}
