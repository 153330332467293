/**
 * App routes
 */
export const EXTERNAL_MODULE = 'external'

export const INTERNAL_MODULE = 'internal'
export const MY_COOKBOOK_ROUTE = 'cook-book'
export const MY_FAMILY_ROUTE = 'family'
export const CREATE_MENU_ROUTE = 'create-menu'
export const CREATE_MENU_PLAN_ROUTE = 'plan'
export const CREATE_MENU_SUMMARY_ROUTE = 'summary'
export const CREATE_QUICK_MENU_ROUTE = 'create-quick-menu'
export const RECIPE_ROUTE = 'recipe'
export const SHOPPING_LIST_ROUTE = 'shopping-list'
export const DREAM_MACHINE_ROUTE = 'dream-machine'

export const ROUTE_PARAM_DAY = 'day'
export const ROUTE_PARAM_MEAL = 'meal'

/**
 * Screen names
 */
export const AppScreens = [
  'dreamMachine',
  'myCookbook',
  'myFamily',
  'createMenuPlan',
  'createMenuSummary',
  'createQuickMenu',
  'recipe',
  'shoppingList'] as const
export type TAppScreen = typeof AppScreens[number]

export interface IAppScreen {
  /**
   * Screen URL after "****.biz/#/"
   */
  route: string
  /**
   * Screen to navigate to whenever "back button" (in header) is pressed.
   */
  goBackScreen?: TAppScreen
}

/**
 * Navigation map for internal module.
 * Order matters!! Most specific -> Less specific
 */
export const InternalNavigationMap:
  Record<TAppScreen, IAppScreen> = {
  'dreamMachine': {
    route: `${INTERNAL_MODULE}/${DREAM_MACHINE_ROUTE}`
  },
  'shoppingList': {
    route: `${INTERNAL_MODULE}/${SHOPPING_LIST_ROUTE}`
  },
  'recipe': {
    route: `${INTERNAL_MODULE}/${MY_COOKBOOK_ROUTE}/${RECIPE_ROUTE}`,
    goBackScreen: 'myCookbook'
  },
  'myCookbook': {
    route: `${INTERNAL_MODULE}/${MY_COOKBOOK_ROUTE}`
  },
  'myFamily': {
    route: `${INTERNAL_MODULE}/${MY_FAMILY_ROUTE}`
  },
  'createMenuPlan': {
    route: `${INTERNAL_MODULE}/${CREATE_MENU_ROUTE}/${CREATE_MENU_PLAN_ROUTE}`,
    goBackScreen: 'myCookbook'
  },
  'createMenuSummary': {
    route: `${INTERNAL_MODULE}/${CREATE_MENU_ROUTE}/${CREATE_MENU_SUMMARY_ROUTE}`,
    goBackScreen: 'createMenuPlan'
  },
  'createQuickMenu': {
    route: `${INTERNAL_MODULE}/${CREATE_QUICK_MENU_ROUTE}`,
    goBackScreen: 'myCookbook'
  }
}

/**
 * Local storage keys
 */
export const ACCEPTED_TERMS_KEY = 'joshu_accepted_terms'

/**
 * Random variables used in the app
 */
export const DEFAULT_DIALOG_STYLE = {
  // 450px is the maximum for the main container (the white card in
  // the middle), so we accept a bit bigger than that => 460px
  // Check dimensions.scss for more accurate understanding of all dimensions.
  maxWidth: 'min(95vw, 460px)',
  width: '100%',
  maxHeight: '85vh'
}

export const CREATING_USER_ID = 'creating_user_id'

export const PROFILE_MIN_AGE = 1
export const PROFILE_MIN_HEIGHT = 1
export const PROFILE_MIN_WEIGHT = 1
export const PROFILE_MAX_AGE = 150
export const PROFILE_MAX_HEIGHT = 300
export const PROFILE_MAX_WEIGHT = 500
